/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Spacing from '../../../../components/layout/Spacing';
import Heading from '../../../../components/text/Heading';
import Button from '../../../../components/Button';
import Container from '../../../../components/layout/Container';
import messages from './messages';
import { cancelFetchNolList, fetchNolList } from '../actions';
import { nolListSelector } from '../../selectors';
import {
  nolListHasPendingSelector,
  nolListHasResolvedSelector,
  nolListPendingSelector,
  nolListResolvedSelector,
} from '../selectors';
import NolListSection from './ListSection';
import { activeJourneySelector } from '../../../journeys/ActiveJourneyProvider/selectors';
import NPSLink from '../../../nps/NPSLink';

const NolList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInitialized } = useSelector(nolListSelector);
  const pendingNols = useSelector(nolListPendingSelector);
  const hasPendingNols = useSelector(nolListHasPendingSelector);
  const resolvedNols = useSelector(nolListResolvedSelector);
  const hasResolvedNols = useSelector(nolListHasResolvedSelector);
  const { isJourneyCreating } = useSelector(activeJourneySelector);

  useEffect(() => {
    dispatch(fetchNolList());

    return () => dispatch(cancelFetchNolList());
  }, []);

  if (!isInitialized) return null;

  return (
    <>
      <Container>
        <Spacing>
          <Heading underlineClass="h-1 bg-cardif-green-light" extraClass="mb-0">
            {intl.formatMessage(messages.title)}
          </Heading>
        </Spacing>
      </Container>
      <NPSLink />
      <Container bgColor="gray">
        {hasPendingNols && (
          <NolListSection
            nols={pendingNols}
            title={intl.formatMessage(messages.listTitle_inProgress)}
            onNolClick={(nol) => navigate(nol.uuid)}
          />
        )}
        {hasResolvedNols && (
          <NolListSection
            isFinished
            nols={resolvedNols}
            title={intl.formatMessage(messages.listTitle_completed)}
            onNolClick={(nol) => navigate(nol.uuid)}
          />
        )}
        <Spacing>
          <Button
            center
            disabled={isJourneyCreating}
            onClick={() => navigate('/journeys/new')}
            label={intl.formatMessage(messages.button)}
          />
        </Spacing>
      </Container>
    </>
  );
};

export default NolList;
