/* eslint-disable no-param-reassign */

// w - current window obj
// d - current document obj
// s - 'script'
// l - 'datalayer'
// i - GTM id

export default (w, d, s, l, i) => {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  j.async = true;
  // OneTrust Integration (ot = OneTrust)
  // Attribute to avoid auto-blocking of GTM
  j.setAttributeNode(d.createAttribute('data-ot-ignore'));
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  f.parentNode.insertBefore(j, f);
};
